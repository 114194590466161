<script setup lang="ts">
import debounce from 'lodash.debounce'
import { ref } from 'vue'
import { useCompareStore } from '../stores/compare'
import CompareModal from './CompareModal.vue'

const compare = useCompareStore()
const compareModalOpen = ref(false)

const textInputDebounce = debounce((refine: any, text: string) => refine(text), 400)
</script>
<template>
  <Teleport defer to="body">
    <CompareModal v-if="compareModalOpen"
    @close="compareModalOpen = false"
    />
  </Teleport>

  <div class="flex flex-col md:flex-row gap-x-4 lg:gap-x-6 w-full py-2 justify-center items-center">
    <div class="flex flex-col justify-center gap-x-6 order-last">
      <!-- order by -->
      <div class="flex items-center w-full">
        <label for="sort" class="sr-only">Sort</label>
        <ais-sort-by
          :class-names="{
            'ais-SortBy': 'flex align-bottom',
            'ais-SortBy-select':
              'block px-0 w-full text-sm text-gray-500 bg-transparent border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer',
          }"
          :items="[
            { value: 'global', label: 'Most Relevant' },
            { value: 'global_price_asc', label: 'Price (Low to High)' },
            { value: 'global_price_desc', label: 'Price (High to Low)' },
            { value: 'global_new_desc', label: 'Newest Listings' },
            { value: 'global_updated_desc', label: 'Recently Updated' },
          ]"
        />

        <!-- compare button -->
        <button
          type="button"
          :class="[
            'inline-flex h-fit items-center px-4 py-1.5 text-sm text-center text-white bg-ahoy-club-teal-secondary rounded-lg hover:bg-ahoy-club-blue focus:ring-4 focus:outline-none focus:ring-blue-300',
            { 'opacity-70 cursor-not-allowed': compare.length === 0 },
          ]"
          @click="
            () => {
              if (compare.length > 0) compareModalOpen = true
            }
          "
        >
          Compare
          <span
            class="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-ahoy-club-blue bg-ahoy-gray rounded-full"
          >
            {{ compare.length }}
          </span>
        </button>
      </div>
    </div>

    <div class="order-first md:flex-1 md:w-full">
      <!-- search text -->
      <label for="search-field" class="sr-only">Search</label>
      <ais-search-box class="w-full self-center">
        <template v-slot="{ currentRefinement, refine }">
          <input
            type="search"
            id="search-yachts"
            class="block h-8 w-full z-20 text-sm text-gray-900 rounded-lg border-blue-100 bg-gray-50 border-1"
            placeholder="Search..."
            :value="currentRefinement"
            @input="textInputDebounce(refine, ($event.target! as any).value)"
          />
        </template>
        <template #reset-icon></template>
        <template #submit-icon> </template>
      </ais-search-box>
    </div>
  </div>
</template>
