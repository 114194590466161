import { ListingProvider } from '@beacon/common/providers'
import { asBrochureListing } from './asBrochureListing'
import { asIndexHit } from './asIndexHit'
import { createNewListings } from './createNewListings'
import { extractAssets } from './extractAssets'
import { fetchListingContent } from './fetchListingContent'
import { refreshCommonLists } from './refreshCommonLists'
import { refreshListings } from './refreshListings'

//
type BindingsProto = {
  BEACON: R2Bucket
  FEED_FETCH_QUEUE: Queue
  FEED_SCANNER_TRIGGER_QUEUE: Queue
  ID_CHART: KVNamespace
  LISTING_ACTIVE: KVNamespace<string>
  YATCO_API_KEY: string
}

// we add an extra function to this provider;
type YatcoListingProvider = ListingProvider<BindingsProto, YatcoListing> & {
  refreshCommonLists: any
}

//
export const YatcoProvider: YatcoListingProvider = {
  name: 'YATCO',
  key: 'yatco',
  //
  asBrochureListing,
  asIndexHit,
  createNewListings,
  extractAssets,
  fetchListingContent,
  refreshListings,
  // extra
  refreshCommonLists,
}

//
export type YatcoListing = {
  isCharter: boolean
  Result: {
    VesselID: number
    MLSID: number
    VesselStatus: number
    VesselCondition: number
    Year: number
    MainPhotoID: number
    VesselType: number
    ModelYear: number
    YearBuilt: number
    MainCategoryID: number
    MainCategoryText: string
    StateRooms: number
    LOAFeet: number
    LOAMeters: number
    BeamFeet: number
    BeamMeters: number
    DraftMinFeet: number
    DraftMinMeters: number
    DraftMaxFeet: number
    DraftMaxMeters: number
    GrossTonnage: number
    GrossTonnagePounds: number
    GrossTonnageConverted: number
    LengthUnit: number
    WeightUnit: number
    VolumeUnit: number
    tags: Array<any>
    AskingPrice: number
    AskingPriceCurrency: number
    PriceOnApplication: boolean
    IsStartingPrice: boolean
    VesselName: string
    AskingPriceFormatted: string
    AskingPriceFormattedNoCurrency: string
    AskingPriceCompare: number
    MSRPPriceFormatted: string
    MSRPPriceFormattedNoCurrency: string
    BuilderName: string
    BrokerTeaser: string
    MainPhotoUrl: string
    MainPhotoMedURL: string
    Model: string
    IsModel: boolean
    SoldDate: string
    ExpireDate: string
    SoldSecret: boolean
    ActiveDate: string
    ModifiedDate: string
    DaysOnMarket: number
    DaysTillExpiration: string
    RecordAgeHours: number
    PDFUrl: string
    AcceptsCrypto: boolean
    ShowOnCustomerSite: boolean
  }
  Accommodations: {
    VesselID: number
    BerthsValue: number
    Berths: string
    HeadsValue: number
    Heads: string
    HeadRoomValue: number
    HeadRoom: string
    SleepsValue: number
    Sleeps: string
    StateRoomsValue: number
    StateRooms: string
    CrewQuartersValue: number
    CrewQuarters: string
    CrewBerthsValue: number
    CrewBerths: string
    CrewSleepsValue: number
    CrewSleeps: string
    CrewHeadsValue: number
    CrewHeads: string
    CaptainQuartersValue: boolean
    CaptainQuarters: string
    CaptainOnBoardValue: boolean
  }
  BasicInfo: {
    VesselID: number
    BuilderID: number
    Builder: string
    BoatName: string
    MainPhotoURL: string
    VesselType: number
    MainCategoryID: number
    MainCategory: string
    SubCategoryID: number
    SubCategory: string
    Model: string
    YearBuilt: number
    ModelYear: number
    RefitYear: number
    LocationCountryID: number
    LocationCountry: string
    LocationCity: string
    LocationStateID: number
    LocationState: string
    Cruising: boolean
    VesselTopID: number
    VesselTop: string
    FlyBridge: boolean
    Cockpit: boolean
    Helipad: boolean
    StateRooms: number
    Sleeps: number
    CrewQuarters: number
    CrewBerths: number
    CrewSleeps: number
    Flag: string
    HandicapAccessible: boolean
    Elevator: boolean
    CurrencyID: number
    Currency: string
    AskingPrice: number
    AskingPriceFormatted: string
    PriceOnApplication: boolean
    NotForSaleInUS: boolean
    VesselCondition: string
    VesselTypeTextISS: string
    VesselTypeText: string
    LocationCustom: string
    LocationCustomRJC: string
    CountryID: number
    Country: string
    LocationCityID: number
    City: string
    StateID: number
    LocationSubRegionID: number
    RegionID: number
    State: string
    LocationSubRegion: string
    Region: string
    Concept: boolean
    Commercial: boolean
    ForCharter: boolean
    AskingPriceFormattedEuro: string
    MainPhotoID: number
    VesselConditionID: number
    BrokerID: number
    CompanyID: number
    OfficeID: number
    isModel: boolean
    SoldPrice: number
    SoldCurrencyID: number
    ProductionTypeID: number
    vesselVirtualTours: Array<any>
  }
  Dimensions: {
    VesselID: number
    LOA: string
    LOAFeet: string
    LOAMeter: string
    LengthUnit: number
    Length: number
    LWLValue: number
    LWL: string
    LWLFeet: string
    LWLMeter: string
    LODValue: number
    LOD: string
    LODFeet: string
    LODMeter: string
    BeamValue: number
    Beam: string
    BeamFeet: string
    BeamMeter: string
    MinDraftValue: number
    MinDraft: string
    MinDraftFeet: string
    MinDraftMeter: string
    MaxDraftValue: number
    MaxDraft: string
    MaxDraftFeet: string
    MaxDraftMeter: string
    ClearanceValue: number
    Clearance: string
    ClearanceFeet: string
    ClearanceMeter: string
    MFGLengthValue: number
    MFGLength: string
    MFGLengthFeet: string
    MFGLengthMeter: string
  }
  SpeedWeight: {
    VesselID: number
    SpeedUnit: number
    WeightUnit: number
    VolumeUnit: number
    CruiseSpeedValue: number
    CruiseSpeed: string
    CruiseSpeedKnots: string
    CruiseSpeedMPH: string
    CruiseSpeedRPMValue: number
    CruiseSpeedRPM: string
    CruiseSpeedRangeValue: number
    CruiseSpeedRange: string
    MaxSpeedValue: number
    MaxSpeed: string
    MaxSpeedKnots: string
    MaxSpeedMPH: string
    MaxSpeedRPMValue: number
    MaxSpeedRPM: string
    MaxSpeedRangeValue: number
    MaxSpeedRange: string
    DisplacementValue: number
    Displacement: string
    DisplacementLBS: string
    DisplacementKG: string
    GrossTonnageValue: number
    GrossTonnage: string
    WaterCapacityValue: number
    WaterCapacity: string
    WaterCapacityGal: string
    WaterCapacityLiters: string
    HoldingTankValue: number
    HoldingTank: string
    HoldingTankGal: string
    HoldingTankLiters: string
    FuelCapacityValue: number
    FuelCapacity: string
    FuelCapacityGal: string
    FuelCapacityLiters: string
    FuelConsumptionValue: number
    FuelConsumption: string
    FuelConsumptionGal: string
    FuelConsumptionLiters: string
    FuelTankMaterialID: number
    WaterTankMaterialID: number
  }
  HullDeck: {
    VesselID: number
    DeckMaterial: string
    ExteriorDesigner: string
    HullColor: string
    HullConfiguration: string
    HullDesigner: string
    HullFinish: string
    HullID: string
    HullMaterial: string
    HullMaterialID: number
    InteriorDesigner: string
    SuperstructureMaterial: string
  }
  Engines: Array<{
    VesselID: number
    Manufacturer: string
    Model: string
    SerialNo: string
    Year: number
    EngineType: string
    FuelType: string
    PropulsionType: string
    Horsepower: number
    AppoxHours: number
    DateHoursReg: string
    DateOverhaul: number
    toString: string
    ReportToString: string
  }>
  MiscInfo: {
    ListingDate: string
    EntryDate: string
    ModifiedDate: string
    ExpirationDate: string
    SoldDate: string
    SoldSecret: boolean
    Status: number
    StatusText: string
    TaxesPaid: string
    AgreementTypeID: number
    AgreementType: string
    VesselDescriptionShortDescription: string
    AvailableCoBroker: number
    ShowOnOtherCompaniesSites: boolean
    ShowOnYatco: boolean
    MLSID: number
    DaysOnMarket: string
  }
  Brokers: Array<{
    RecID: number
    VesselID: number
    BrokerID: number
    FirstName: string
    LastName: string
    Phone: string
    Mobile: string
    CompanyLogoURL: string
    PhotoURL: string
    Email: string
    CompanyID: number
    OfficeID: number
    Associations: string
    CompanyName: string
    Company_Address1: string
    Company_City: string
    Company_State: string
    Company_Country: string
    Company_Zip: string
    ExternalBroker: boolean
    toString: string
    Company: {
      CompanyID: number
      ParentCompanyID: number
      CompanyName: string
      Address1: string
      City: string
      StateID: number
      State: string
      PostalCode: string
      CountryID: number
      Country: string
      MainPhone: string
      Website: string
      EMMCustomerID: string
      EMMSecKey: string
      Email: string
      PhotoID: number
      YATCOFSVesselSync: boolean
      YATCOAPIKEY: string
      YATCOLeadRouting: boolean
      CompanyTypeIDs: Array<number>
      ImageSmallUrl: string
      ImageMediumUrl: string
      ImageLargeUrl: string
      IsPayingForForSale: boolean
      IsPayingForCharter: boolean
      TestCompany: boolean
    }
    Office: {
      OfficeID: number
      CompanyID: number
      OfficeName: string
      Address1: string
      City: string
      StateID: number
      State: string
      Zip: string
      CountryID: number
      Country: string
      IsParent: boolean
      Enabled: boolean
      YATCOCOMPANYID: number
      Email: string
      IsActiveCompany: boolean
      IsDeletedOffice: boolean
      IsEnabledOffice: boolean
      LocationCountryID: number
      LocationCountry: string
      LocationStateID: number
      LocationState: string
      LocationCityID: number
      LocationCity: string
    }
  }>
  VD: {
    DataSource: number
    VesselID: number
    CultureID: number
    VesselDescriptionShowingInstructions: string
    VesselDescriptionBrokerTeaser: string
    VesselDescriptionShortDescription: string
    VesselDescriptionShortDescriptionStripped: string
    VesselDescriptionShortDescriptionNoStyles: string
    VesselDescriptionInternalTeaser: string
    VesselName: string
    Loading_ShortDescription: boolean
    Loading_MyWebSiteDescription: boolean
    Loading_YatcoDotComDescription: boolean
  }
  SEO: {
    VesselID: number
  }
  TenderInfo: {
    VesselID: number
  }
  ClassificationInfo: Array<any>
  dataSource: number
  PhotoGallery: Array<{
    PhotoID: number
    CultureID: number
    VesselID: number
    SortOrder: number
    Width: number
    Height: number
    OriginalSize: number
    PhotoCategory: number
    Caption: string
    largeImageURL: string
    mediumImageURL: string
    smallImageURL: string
    MimeType: string
  }>
  Layouts: Array<any>
  Videos: Array<any>
  Sections: Array<any>
  Company: {
    CompanyID: number
    CompanyName: string
    isDeleted: boolean
    isActive: boolean
    Disclaimer: string
    CreateDate: string
    ActiveDate: string
    Address1: string
    PostalCode: string
    MainPhone: string
    Website: string
    ITContactName: string
    ITContactPhone: string
    ITContactEmail: string
    MarketingContactName: string
    MarketingContactPhone: string
    MarketingContactEmail: string
    Email: string
    PhotoID: number
    CompanyFaceBook: string
    CompanyInstagram: string
    CompanyYouTube: string
    EMMCustomerID: string
    LocationCountryID: number
    LocationCountry: string
    LocationStateID: number
    LocationState: string
    LocationStateCode: string
    LocationCityID: number
    LocationCity: string
    BillingCountryID: number
    BillingCreditCardTypeID: number
    YATCOCOMPANYID: number
    CompanyTypeIDs: Array<number>
    AssignedBuilderIDs: Array<any>
    IsPayingForForSale: boolean
    IsPayingForCharter: boolean
    ImageSmallUrl: string
    ImageMediumUrl: string
    ImageLargeUrl: string
    ModifiedDate: string
    MailgunDomainConfigured: boolean
    IsEuropeMailgunDomain: boolean
  }
}
