import { IndexHit } from '@beacon/common/types'

/**
 * Return the "secret" brochure id with the cloaked brochure type injected
 * @param id The brochure id
 * @param brand use 'a' for Ahoy, 'r' for Ray White, 'p' for plain.
 */
export const generateBrochureIdForProvider = (id: string, brand: string): string => {
  const start = id.substring(0, 3)
  const end = id.substring(3, id.length)
  return `${start}${brand}${end}`
}

// Open the compare in a new tab
export const openCompare = (baseUrl: string, hits: IndexHit[], brand: string, target: string = '_blank') => {
  const brochureIds = hits //
    .map((e: IndexHit) => e.brochureId) //
    .map((brochureId: string) => generateBrochureIdForProvider(brochureId, brand))
  //
  window.open(`${baseUrl}/${brochureIds.join('/')}`, target)
}

// Open the brochure in a new tab
export const openBrochure = (baseUrl: string, brochureId: string, brand: string, target: string = '_blank') => {
  window.open(`${baseUrl}/${generateBrochureIdForProvider(brochureId, brand)}`, target)
}

// Copy the brochure URL to the clipboard
export const copyBrochureURL = async (baseUrl: string, brochureId: string, brand: string) => {
  const text = `${baseUrl}/${generateBrochureIdForProvider(brochureId, brand)}`
  try {
    await navigator.clipboard.writeText(text)
  } catch ($e) {
    console.error('Failed to copy', $e)
  }
}
