<script lang="ts" setup>
import { round } from '@beacon/common/lang/util'
import { Providers } from '@beacon/common/providers'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { TrashIcon } from '@heroicons/vue/24/solid'
import draggable from 'vuedraggable'
import { useCompareStore } from '../stores/compare'
import { openCompare } from '../utils/brochure'
import { priceShort, setBrokenImagePlaceholder } from '../utils/display'

const compare = useCompareStore()

const emits = defineEmits<{
  (e: 'close'): void
}>()
</script>
<template>
  <TransitionRoot as="template" :show="true">
    <Dialog class="relative z-[9999]" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="$emit('close')"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="size-6" aria-hidden="true" />
                </button>
              </div>

              <div class="grid grid-cols-1 md:grid-cols-[66%_33%]">
                <div class="w-full">
                  <div class="flex justify-between pb-4">
                    <h3 class="text-base text-gray-500">Selected yachts in this comparison</h3>
                    <button
                      type="button"
                      @click="compare.clear()"
                      class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >
                      <TrashIcon class="size-4 text-white me-2" />
                      Remove All
                    </button>
                  </div>

                  <draggable
                    class="grid grid-cols-1 divide-y-2 rounded-lg"
                    :list="compare.hits as any"
                    group="compare-items"
                    itemKey="index"
                    ghostClass="bg-green-200"
                  >
                    <template #item="{ element: hit }">
                      <div class="flex justify-between items-center p-4 bg-gray-50">
                        <div class="flex flex-nowrap hover:cursor-grab">
                          <div class="flex gap-x-4">
                            <div class="w-32">
                              <img
                                class="w-full object-contain rounded-md"
                                :src="`${hit.thumbnail}`"
                                @error="setBrokenImagePlaceholder($event)"
                              />
                            </div>
                            <div class="grid grid-cols-1 content-start">
                              <span class="font-semibold">{{ hit.name }}</span>
                              <div class="flex items-start gap-2">
                                <!-- Provider -->
                                <span class="">{{ Providers[hit.provider].name }}</span>
                                <!-- Price -->
                                <span v-html="priceShort(hit.priceAsking) || '&mdash;'"></span>
                                <!-- Length -->
                                <span
                                  v-html="
                                    round(hit.length, 1) + 'm (' + (hit.length * 3.2808398950131).toFixed(0) + 'ft)' ||
                                    '&mdash;'
                                  "
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <TrashIcon
                          @click="compare.remove(hit.objectID)"
                          class="size-6 fill-red-700 rounded-lg hover:fill-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:fill-red-600 dark:hover:fill-red-700 dark:focus:ring-red-800"
                        />
                      </div>
                    </template>
                  </draggable>
                </div>

                <div class="mx-auto sm:pt-12 grid grid-col-1 gap-4 content-start">
                  <button
                    type="button"
                    @click="openCompare('https://sales.ahoyclub.com/compare', compare.hits as any, 'a')"
                    class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal focus:ring-1 focus:outline-none focus:ring-ahoy-club-teal"
                  >
                    Ahoy Club
                  </button>

                  <button
                    type="button"
                    @click="openCompare('https://sales.ahoyclub.com/compare', compare.hits as any, 'r')"
                    class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-[#595959] bg-[#ffe512] rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-[#ffe512]"
                  >
                    Ray White Marine
                  </button>

                  <button
                    type="button"
                    @click="openCompare('https://yachtbac.com/compare', compare.hits as any, 'b')"
                    class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-ahoy-club-blue bg-gray-400 rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-gray-400"
                  >
                    Broker Friendly
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
