import { AssetMap, BrochureListing, ListingContent, ListingInfo } from '@beacon/common/types'
import { BoatsOnlineListing } from './index'

/**
 * Map a Boats Online raw listing to a BrochureListing
 *
 * @param inf the listing information
 * @param raw the raw listing
 */
export const asBrochureListing = (
  _inf: ListingInfo,
  content: ListingContent<BoatsOnlineListing>,
  assetMap: AssetMap,
): BrochureListing => {
  //
  let raw: BoatsOnlineListing
  if (typeof content.body === 'string') {
    raw = JSON.parse(content.body)
  } else {
    raw = content.body
  }

  // NB: Keep the type shape for clarity in the code.
  const brochureListing: BrochureListing = {
    name: raw.VesselName,
    title: raw.VesselName,
    //
    accommodation: {
      accommodation: raw.Accomodation
    },
    entertainment_and_av_equipment: [raw.Electrics, raw.Electronics, raw.Entertainment].filter((e) => e).join(', '),
    galley_and_laundry_equipment: raw.Galley || undefined,
    images: assetMap.map(raw.Resources.Image).filter((e) => e !== undefined),
    new_listing: raw.new_boat ? new Boolean(raw.new_boat).valueOf() : undefined,
    safety_and_security_equipment: raw.SafetyGear || undefined,
    sold_banner: raw.sold === 'Sold',
    //
    broker_details: {
      image: undefined,
      is_broker: undefined,
      job_title: undefined,
      name: undefined,
      personal_blurb: undefined,
      phone_number: undefined,
    },
    hull_and_deck: {
      date_keel_laid: undefined,
      deck_material: raw.DecksMaterial || undefined,
      exterior_designer: undefined,
      hin: raw.HIN || undefined,
      hull_colour: undefined,
      hull_configuration: undefined,
      hull_designer: undefined,
      hull_material: raw.HullMaterial || undefined,
      hull_number: undefined,
      interior_designer: undefined,
      superstructure_material: undefined,
      warranty: undefined,
    },
    main_spec: {
      length_meters: raw.Length ? raw.Length : 0,
      model: raw.boatmodel ? raw.boatmodel : '',
      price: raw.Price ? `${raw.Currency}${raw.Price}` : 'POA',
      //
      beam: raw.Beam ? new Number(raw.Beam).valueOf() : undefined,
      builder: raw.Builder || undefined,
      cabins: raw.Cabins || 0,
      type: undefined,
      classification: undefined,
      construction: undefined,
      crew: undefined,
      decks: undefined,
      description: raw.descript || undefined,
      detailed_description: undefined,
      draft_length: raw.Draft ? new Number(raw.Draft).valueOf() : undefined,
      exterior_designer: raw.Designer || undefined,
      flag: undefined,
      gross_tonnage: undefined,
      imo_number: undefined,
      interior_designer: raw.Designer || undefined,
      ism: undefined,
      lying: raw.Location || undefined,
      main_image: assetMap.get(raw.Thumbnail),
      mci: undefined,
      mmsi_number: undefined,
      refit_type: undefined,
      sleep: undefined,
      specifications: undefined,
      vessel_top: undefined,
      year_built: raw.Year || 0,
      year_refit: undefined,
    },
    mechanical_equipment: {
      air_compressor: undefined,
      air_conditioning: undefined,
      antifouling_system: undefined,
      batteries: undefined,
      battery_chargers: undefined,
      bow_thruster: raw.Thrusters || undefined,
      dirty_oil_pump: undefined,
      emergency_generator: undefined,
      engine_controls: undefined,
      engine_mounts: undefined,
      engine_room_ventilation: undefined,
      engine_ventilation: undefined,
      engines_exhaust_system: undefined,
      fresh_water_makers: undefined,
      fresh_water_pressure: undefined,
      fresh_water_treatment: undefined,
      fuel_filling_pump: undefined,
      fuel_filters: undefined,
      fuel_filtration: undefined,
      fuel_oil_separator: undefined,
      fuel_transfer_pump: undefined,
      gear_boxes: undefined,
      generator_hours: undefined,
      generators: undefined,
      grey_water_holding_tanks: undefined,
      grey_water_system: undefined,
      hot_and_cold_circulation_pump: undefined,
      lubrication: undefined,
      oil_filtration: undefined,
      oil_pump: undefined,
      output_electricity: undefined,
      propellers: undefined,
      propulsion_type: raw.Propulsion || undefined,
      rudders: undefined,
      sewage_system: undefined,
      shaft_log_and_acking_glands: undefined,
      shafting: undefined,
      shore_power: undefined,
      stabilizers: undefined,
      steering_system: undefined,
      stern_thruster: raw.Thrusters || undefined,
      under_water_lights: undefined,
      wc: undefined,
      engine_1: {
        approx_hours: `${raw.EngineHours}` || undefined,
        date_hours_reg: undefined,
        fuel: raw.FuelType || undefined,
        horsepower: raw.HorsePower || undefined,
        last_service: undefined,
        manufacturer: undefined,
        model: raw.EngineMake || undefined,
        serial: undefined,
        type: undefined,
      },
      engine_2: {
        approx_hours: `${raw.EngineHours2}` || undefined,
        date_hours_reg: undefined,
        fuel: raw.FuelType || undefined,
        horsepower: raw.HorsePower || undefined,
        last_service: undefined,
        manufacturer: undefined,
        model: raw.EngineMake || undefined,
        serial: undefined,
        type: undefined,
      },
    },
    navicom_equipment: {
      ais: undefined,
      auto_pilot: undefined,
      charts: undefined,
      depth_sounder: undefined,
      dgps: undefined,
      echo_sounder: undefined,
      electronic_chart_system: undefined,
      eprib: undefined,
      gdmss: undefined,
      gps: undefined,
      gyrocompass: undefined,
      horn: undefined,
      inmarsat_c: undefined,
      log: undefined,
      magnetic_compass: undefined,
      navigation_bridge: undefined,
      navtex: undefined,
      radar: undefined,
      radio: undefined,
      rudder_angle_indicator_system: undefined,
      satcom: undefined,
      search_lights: undefined,
      ships_computer: undefined,
      ships_monitoring: undefined,
      ssb: undefined,
      telephone_system: undefined,
      tender_tracking_system: undefined,
      vdr: undefined,
      vhf: undefined,
    },
    speed_capacity_and_weight: {
      cruise_speed: raw.CruiseSpeed ? new Number(raw.CruiseSpeed).valueOf() : undefined,
      cruise_speed_range: undefined,
      dirty_lube_oil: undefined,
      fresh_water_capacity: raw.WaterCapacity || undefined,
      fuel_capacity: raw.FuelCapacity || undefined,
      fuel_consumption: undefined,
      grey_black_water_capacity: undefined,
      lube_oil: undefined,
      max_speed: raw.MaxSpeed ? new Number(raw.MaxSpeed).valueOf() : undefined,
      waster_oil: undefined,
    },
  }

  return brochureListing
}
