import { IndexHit } from '@beacon/common/types'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useStorage } from '@vueuse/core'

/**
 * The "store" that is used to perform the compare; persists to local storage.
 *
 * The base items used are Algolia hits, to keep things consistent/simple.
 */
export const useCompareStore = defineStore('compare-items', () => {
  // the hits.
  const hits = useStorage<IndexHit[]>('compare-items', [])

  /**
   * The number of items in the compare list
   */
  const length = computed(() => hits.value.length)

  /**
   * Add a hit to the compare list
   * @param hit the hit to add to the compare
   */
  function add(hit: IndexHit) {
    // don't take shit.
    if (!hit) {
      return
    }
    // don't double up.
    if (includes(hit.objectID)) {
      return
    }

    hits.value.push(hit)
  }

  /**
   * Does the compare list include a uri
   *
   * @param uri value to find
   * @returns true if it includes the hit (based on uri), false otherwise
   */
  function includes(uri: string) {
    return hits.value.some((e) => e.objectID === uri)
  }

  /**
   * Remove a hit from the compare list by uri
   *
   * @param uri the uri of the hit to remove
   */
  function remove(uri: string) {
    hits.value = hits.value.filter((e) => e.objectID !== uri)
  }

  /**
   * Clear the compare list
   */
  function clear() {
    hits.value = []
  }

  //
  return { hits, length, add, remove, includes, clear }
})
